.ClockStyle {
    font-size: 2.5em;
    
    color: aliceblue;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 2px #000000;
    padding:20px;
}

.ClockText {
    color: #f9f9f9;
    font-family: Ink Free;
    font-size: 3.5em;
    font-weight: bold;

    text-align: center;
    text-shadow:5px 5px #000000;
}
@media only screen and (max-width: 1200px) {
    .ClockStyle{
        font-size: 1.2em;
    }
    .ClockText{
        font-size:1.5em;

    }
}

