
.hero {
    min-height: 100vh;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

    .hero__overlay{
        position: absolute;
        top: 0;
        bottom: 0;
        left:0;
        right: 0;
        background-color: var(--overlay-color);
        display: flex;
        align-items: center;
    }
}

.header-img {
    height: clamp(150px, 20vw, 400px);
    width: clamp(150px, 20vw, 400px);
}
.hero_container {
    height: 65vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.hero_logoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hero_subtitle {
    text-align: center;
    background: #243c96;
    color: #fff;
    font-size: clamp(1.0rem,1.4vw + .8rem,2.25rem);
    font-weight: bold;
    padding: 0.1rem 1.5rem;
    text-transform: uppercase;
}

.clock_container {
    padding-top: 60px;
}

//Dropdown Buttons
div .main-dropdown {
    background: #E02628;
    border: #E02628;
    width: 50% !important;
}

div .main-dropdown:first-child:hover, div .main-dropdown:first-child:active, div .main-dropdown:first-child:focus-visible {
    background: #a50e26;
    border: #a50e26;
    box-shadow: none;
}

    
.dropdown-content {
    display: flex;
    justify-content: center;
    border: none;
    background: none !important;
    margin-top: .5rem;
    padding: 1.25rem 0;
    width: 50% !important;
}

.dropdown-content:after {
    content: '';
    border: inherit;
    border-radius: inherit;
    background: rgba(255, 255, 255, 0.4);
    z-index: -1;
    inset: 0;
    position: absolute;
}

div .dropdown-button {
    width: 45%;
    padding: 0.75rem 2.5rem;
    background: #E02628;
    border: #E02628;
}
div .dropdown-button:hover, div .dropdown-button:active {
    background: #a50e26 !important;
    border: #a50e26 !important;
}

.row > .btn.show {
    background: #a50e26;
    border: #a50e26;
}

.smiles-section {
    min-height: 40vh;
    background: #e02628;
    color: white;

    .smiles-section__calculator {
        max-width: 400px;
    }
}

.agenda_container {
   background-color: rgba(0, 0, 0, 0.15);
}


.styled-table {
    background: white;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-top: 25px;
}

.styled-table thead tr {
    background-color: #3f3f3f;
    color: #ffffff;
}

.styled-table th,
.styled-table td {
    border: 1px solid #d1c5c5;
    padding: 12px 15px;
    max-width: 200px;
    text-align: center;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr td:first-child {
    background-color: #f3f3f3;
    font-weight: bold;
}


    @media only screen and (min-width: 845px) {
        .hotel-section .row {
            text-align: right;
            flex-direction: row-reverse;
        }
    }

    @media (max-width: 844px) {
        .hero {
            background-attachment: initial;
        }

        .hero__overlay {
            position: relative !important;
        }
        .hero_container {
            align-content: flex-start;
            height: 80vh;
            position: relative;
            top: 100px;
        }

        .headerImage {
            width: 80%;
            margin: auto;
            display: block;
            padding-top: 200px;
        }

        .clock_container {
           display: none;
        }

        div .main-dropdown {
            width: 85% !important;
        }
        div .dropdown-content {
            width: 85% !important;
        }
        .dropdown-button {
            display: flex;
            justify-content: center;
        }
        .table_container {
            display: flex;
            overflow-x: auto;
            width: 95%;
            justify-content: space-around;
        }
    }




