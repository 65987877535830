

.carousel__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9108018207282913) 100%);
    
}

.carousel-inner{
    height:100%
}
.carousel-item img {
    object-fit: cover;
}

.carousel-caption {
    padding-inline: 0.75rem;
    max-width: 70vw;
    background-color: rgb(255, 255, 255, 0.3);
    border-radius: 15px;
    border-style: solid;
    padding-bottom: 0.5rem !important;
    padding-top: 0rem !important;
    right: 20% !important;
    left: 20% !important;
    bottom: 2.2rem;
}

.carousel-caption h2{
    margin-bottom:0rem!important;
}
.carousel-caption p {
    margin-bottom: 0rem !important;
}

.carousel-indicators{
    margin-bottom:0rem!important;
}



.city-view-banner{
    height: 40vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.hotel-summary{
    
    .row{
        min-height: 35vh;
    }
}




.hotel-summary .row{
    margin-top:10px;
}




@media only screen and (min-width: 580px) {
    .carousel-caption{
        display: block;
    }
}

#wide{
    display:none;
}
#narror {
    display: none;
}


@media only screen and (min-width: 900px) {
    .carousel{
        min-height: 50vh;
    }
    #wide{
        display:block;
    }
    
    
}
@media (max-width:900px) {
   
    #narror {
        display: block;
    }
    
}

@media (max-width:580px){
    .d-block {
        height: 23vh;
    }
    
}