@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Unbounded&display=swap');




:root {
    --main-font: 'Questrial', sans-serif;
    --nav-font: 'Unbounded', cursive;
    --overlay-color: #23232963;
    --text-size: 18px;
    --bs-primary-rgb: rgba(36,60,150) !important;
}

main{
   height:100vh;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}


h1,h2,h3,h4,h5,h5, p, button, .btn{
    font-family: var(--main-font) !important;
}

p {
    font-size: var(--text-size);
}

img{
    width: 100%;
}


.text-bold{
    font-weight: bold !important;
}

.banner-overlay{
    position:absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #1c1c1ca3;
}

#content {
    flex-grow: 1;
    margin-bottom: 3rem !important;
}

footer p a{
    color: white;
    text-decoration: none;
}


@keyframes fadeIn {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}


@media only screen and (min-width: 1200px) {
    ::-webkit-scrollbar{
        width: 10px;
    }
    
    ::-webkit-scrollbar-track{
        background-color: rgb(74, 74, 74);
    }
    
    
    ::-webkit-scrollbar-thumb{
        background: rgb(32, 32, 32);
        border-radius: 5px;
    }
}