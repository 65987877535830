.tour-image{
    min-height: 40vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.tour-image__overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.509);
}

.tour-backgroundimage {
    
    background-image: url(https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/optional-tours/cupyb43e8vl4w2n7.jpg);
    background-size: cover;
    background-position: top center;
    padding: 0 !important;
    background-attachment: fixed;
    opacity: 1;
    z-index: -1;
    
}

.overlay {
    position: relative;
    opacity: 1;
    z-index: 1;
}