
.navbar {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;
    font-family: var(--main-font);
    position: sticky !important;
}

.logo-small {
    width: 65px;
    height: 65px;
}