.about-header {
    background-attachment: local;
    background-position: 50% 80%;
    background-size: cover;
    min-height: 70vh;
}

.about-summary{
    margin: 25px auto;
}

.display-2 {
    position: relative;
    padding: 0.2rem 0.75rem;
    font-size: 5.5rem;
}

.display-2::after {
    inset: 0;
    z-index: 99;
    background: #0000008a;
    content: '';
    position: absolute;
    z-index: -1;
    filter: blur(12px);
}


.districts-container{

    margin: 50px auto;
}

.district-card{
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 460px;
}

.district-card__overlay{
    opacity: 0;
    transition: 0.2s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.772);
    p {
        font-size: 14px;
    }
}

.district-card__overlay:hover{
    opacity: 1;
}
@media (max-width: 844px) {
    .about-header {
        background-attachment: initial;
    }
     
}