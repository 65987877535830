
.accordion-item{
    border: none !important;

    .accordion-button{
        font-size: 1.2rem;
    }

    .accordion-button:focus {
        box-shadow: none;
    }

    .accordion-button:not(.collapsed){
        background-color: rgb(248,249,250)
    }

    .accordion-button:not(.collapsed)::after {
        background-image: var(--bs-accordion-btn-icon);
        transform: var(--bs-accordion-btn-icon-transform);
    }
}

.accordion {
    
    border-top: 3px dashed #0f0f0f;
    padding-top: 30px;
    
}

