.flights-summary{
    margin: 25px auto;
}

.flight-cards{
}

button.accordion-button {
    font-weight: bold !important;
    background: #ebebeb !important;
    border-radius: 0 !important;
}

.flight-cards::-webkit-scrollbar{
    width: 10px;
}

.flight-cards::-webkit-scrollbar-track{
    background-color: rgb(223, 223, 223);
}


.flight-cards::-webkit-scrollbar-thumb{
    background: rgb(179, 179, 179);
    border-radius: 5px;
}

.timeline{
    display: block;
    width: 75%;
    height: 2px;
    background-color: #b8aeae;
}