.important-information-container {
    background-image: url(https://newsunfiles.blob.core.windows.net/iceworks-24-images/Iceworks_24_London_Aerial.webp);
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
    padding: 0 !important;

    & > .overlay {
        background-color: rgb(29 29 29 / 76%);
    }
}



.important-information-list{
    list-style-type: decimal-leading-zero;

    & > li{
        list-style-position: inside;
    }

    & > li span{
        color: white;
        margin-bottom: 0.5rem;
        display: inline-block;
    }
    
    & > li::marker{
        font-weight: bold;
        font-size: calc(1.425rem + 2.1vw);
    }
}



@media only screen and (min-width: 1200px) {
    
    .important-information-list{
       
        & > li{
            margin-bottom: 2rem;
        }
        
        & > li::marker{
            font-size: 3rem;
        }
    }
    
}